import { request } from "../../utils";

const checkUsernameUnique = (username: string) => {
  return request.get<{ isUnique: boolean }>("/api/user/isUnique", {
    params: {
      username: username,
    },
  });
};

export default checkUsernameUnique;
