import { request } from "@src/utils";

const deleteAvatar = async (id: number | string, filename: string) => {
  const res = await request.delete(`/api/upload/avatar/${id}`, {
    params: { key: filename },
  });
  if (res.status == 200) return res.data;
};

export default deleteAvatar;
