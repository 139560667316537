import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Lottie from "lottie-web";

import LottieLogo from "@src/assets/lotties/mg_loop_crop.json";

const Logo = styled.div`
  width: 240px;
  height: 144px;
`;

const AnimatedLogo = () => {
  const logoEl = useRef(null);
  // const lottieOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: LottieLogo,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  useEffect(() => {
    if (logoEl.current) {
      Lottie.loadAnimation({
        container: logoEl.current, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: LottieLogo,
      });
    }
  }, []);

  return <Logo ref={logoEl} />;
};

export default AnimatedLogo;
