import { Web3 } from "@src/interfaces/web3";

export enum Web3ActionType {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}
interface Activate {
  type: Web3ActionType.ACTIVATE;
  payload: {
    web3: Web3 | null;
  };
}

interface Deactivate {
  type: Web3ActionType.DEACTIVATE;
}

type Web3Action = Activate | Deactivate;

export interface Web3State {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  activate?: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  library?: any;
  web3: Web3 | null | undefined;
}

export const initialState: Web3State = {
  web3: null,
  activate: null,
  library: null,
};

const web3Reducer = (state: Web3State, action: Web3Action): Web3State => {
  switch (action.type) {
    case Web3ActionType.ACTIVATE:
      const web3 = action.payload.web3;
      if (!web3) return state;
      return {
        ...state,
        web3: {
          ...web3,
        },
      };
    case Web3ActionType.DEACTIVATE:
      return {
        ...state,
        web3: null,
      };
    default:
      return state;
  }
};

export default web3Reducer;
