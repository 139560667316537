import { request } from "@src/utils";

const uploadAvatar = async (id: number | string, file: File) => {
  const formData = new FormData();
  formData.append("avatar", file);
  const res = await request.post<{ path: string }>(
    `/api/upload/avatar/${id}`,
    formData,
    {}
  );
  if (res.status == 200) return res.data.path;
};

export default uploadAvatar;
