import React, { ReactNode, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";

ReactModal.setAppElement("#root");

const styles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: 0,
    border: 0,
    padding: 0,
    transform: "translate(-115%, -50%)",
    width: "420px",
    minHeight: "420px",
    paddingBottom: 48,
    display: "flex",
    fontSize: 19,
    flexDirection: "column",
    background: "#00ff00",
    overflow: "hidden",
    boxShadow: "0 2px 12px rgba(0,155,0,0.3)",
    pointerEvents: "all",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    pointerEvents: "none",
    zIndex: 999,
  },
};

const mobileStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: 0,
    border: 0,
    padding: 0,
    // marginLeft: "0%",
    transform: "translate(-50%, -50%)",
    maxWidth: "420px",
    width: "90%",
    height: "auto",
    fontSize: 16,
    paddingBottom: 50,
    display: "flex",
    flexDirection: "column",
    background: "#00ff00",
    overflow: "hidden",
    boxShadow: "0 2px 12px rgba(0,155,0,0.3)",
    pointerEvents: "all",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    pointerEvents: "none",
    zIndex: 999,
  },
};

const Body = styled.div`
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2;
  flex: 1;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  padding: 1.3em;

  & a {
    border-bottom: 1px solid black;
  }
`;

const CloseButton = styled.div`
  width: 2em;
  height: 2em;
  z-index: 2;
  position: absolute;
  left: 1.3em;
  bottom: 0.65em;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: relative;
    width: 1px;
    height: 2em;
    background: black;
    transform-origin: right top;
    transform: rotate(-45deg) translate(0, 0);
  }
  &:after {
    content: "";
    display: block;
    position: relative;
    width: 1px;
    height: 2em;
    background: black;
    transform-origin: left top;
    transform: rotate(225deg) translate(0.32em, 0.4em);
  }
`;
const Modal = (props: {
  startDate: string;
  endDate: string;
  children?: ReactNode;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const [visible, setVisible] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const { startDate, endDate, children } = props;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" && isFirst) {
      setIsFirst(false);
      const start = new Date(startDate);
      const now = new Date();
      const end = new Date(endDate);
      if (start.getTime() <= now.getTime() && now.getTime() <= end.getTime()) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else if (location.pathname !== "/") {
      setVisible(false);
    }
  }, [location, isFirst, startDate, endDate]);

  return (
    <React.Fragment>
      <ReactModal isOpen={visible} style={isMobile ? mobileStyles : styles}>
        <Body>{children}</Body>
        <CloseButton theme={"dark"} onClick={() => setVisible(false)} />
      </ReactModal>
    </React.Fragment>
  );
};

export default Modal;
