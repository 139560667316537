import axios from "axios";

const request = {
  get: function <T>(url, config) {
    return axios.get<T>(url, config);
  },
  post: function <T>(url, data, config) {
    return axios.post<T>(url, data, config);
  },
  delete: function <T>(url, config) {
    return axios.delete<T>(url, config);
  },
};

export default request;
