import { request } from "@src/utils";

export interface followForm {
  action: string;
  followee?: string;
  follower?: string;
}

const follow = (followForm: followForm) => {
  return request.post<{ success?: boolean; id?: number }>(
    "/api/user/follow",
    followForm,
    {}
  );
};

export default follow;
