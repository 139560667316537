import { request } from "@src/utils";

const getUserByAddress = (walletAddress: string) => {
  return request.get<{ username: string | null; message: string }>(
    `/api/user/address/${walletAddress}`,
    {}
  );
};

export default getUserByAddress;
