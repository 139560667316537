import React, { Reducer, ReducerState, useEffect, useReducer } from "react";

function usePersistedReducer<S, A>(
  reducer: Reducer<S, A>,
  initialState: ReducerState<Reducer<S, A>>,
  storageKey: string
): [S, React.Dispatch<A>] {
  const init = (initialState) => {
    if (localStorage.getItem(storageKey)) {
      return JSON.parse(localStorage.getItem(storageKey));
    }
    return initialState;
  };

  const [state, dispatch] = useReducer(reducer, initialState, init);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  return [state, dispatch];
}

export default usePersistedReducer;
