import { User } from "@src/interfaces";
import { request } from "@src/utils";

const listFollows = (id: number | string) => {
  return request.get<{ follower: User[]; following: User[] }>(
    "/api/user/follow",
    { params: { id } }
  );
};

export default listFollows;
