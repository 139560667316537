import { User } from "@src/interfaces";
import { request } from "@src/utils";

const authenticateUser = (walletAddress: string, signature: string) => {
  return request.post<User>(
    "/api/user/signin",
    { walletAddress, signature },
    {}
  );
};

export default authenticateUser;
