import React, { ReactNode, useEffect, createContext, useMemo } from "react";
import { useEagerConnect } from "@src/hooks/web3";
import { usePersistedReducer } from "@src/hooks";
import web3Reducer, {
  initialState,
  Web3ActionType,
  Web3State,
} from "@src/contexts/web3Reducer";

export const Web3ReactContext = createContext<Web3State>(initialState);
const storageKey = "WEB3_STATE";

interface InjectedWeb3ProviderProps {
  children: ReactNode;
}

const PersistedWeb3ReactProvider = (props: InjectedWeb3ProviderProps) => {
  const [walletAddress, chainId, activate, library] = useEagerConnect();
  const [state, dispatch] = usePersistedReducer(
    web3Reducer,
    initialState,
    storageKey
  );
  useEffect(() => {
    if (walletAddress && chainId) {
      dispatch({
        type: Web3ActionType.ACTIVATE,
        payload: {
          web3: {
            walletAddress: String(walletAddress).toLowerCase(),
            chainId: String(chainId),
          },
        },
      });
    } else {
      dispatch({
        type: Web3ActionType.DEACTIVATE,
      });
    }
  }, [walletAddress, chainId]);

  const value = useMemo(() => {
    return {
      ...(state as Web3State),
      activate,
      library,
    };
  }, [state, activate, library]);

  return (
    <Web3ReactContext.Provider value={value}>
      {props.children}
    </Web3ReactContext.Provider>
  );
};

export default PersistedWeb3ReactProvider;
