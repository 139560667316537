import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "@emotion/styled";
import Spinner from "@src/components/common/atoms/Spinner";
import Header from "@src/components/Header";
import Popup from "@src/components/common/molecules/Modal/popup";
// import { Link } from "react-router-dom";

const HomePage = lazy(() => import("@src/pages/Home"));
const ProjectsPage = lazy(() => import("@src/pages/Projects"));
const ProjectPage = lazy(() => import("@src/pages/Project"));
const ArtworkPage = lazy(() => import("@src/pages/Artwork"));
const ArtistsPage = lazy(() => import("@src/pages/Artists"));
const ArtistPage = lazy(() => import("@src/pages/Artist"));
const ExplorePage = lazy(() => import("@src/pages/Explore"));
const SignInPage = lazy(() => import("@src/pages/SignIn"));
const ProfileEditPage = lazy(() => import("@src/pages/Profile"));
const AboutPage = lazy(() => import("@src/pages/About"));
const NotFoundPage = lazy(() => import("@src/pages/NotFound"));
const IncheonAirportPage = lazy(() => import("@src/pages/IncheonAirport"));

const routes = [
  {
    component: HomePage,
    path: "/",
  },
  {
    component: IncheonAirportPage,
    path: "/incheon-airport",
  },
  {
    component: IncheonAirportPage,
    path: "/incheon-airport/:artistName",
  },
  {
    component: ProjectsPage,
    path: "/projects",
  },
  {
    component: ArtworkPage,
    path: "/artwork/:artworkId/:edition?",
  },
  {
    component: ProjectPage,
    path: "/project/:projectId",
  },
  {
    component: ExplorePage,
    path: "/catalog",
  },
  {
    component: ArtistsPage,
    path: "/artists",
  },
  {
    component: ArtistPage,
    path: "/artist/:userId",
  },
  {
    component: AboutPage,
    path: "/about",
  },
  {
    component: SignInPage,
    path: "/signin",
  },
  {
    component: ArtistPage,
    path: "/user/:userId",
  },
  {
    component: ProfileEditPage,
    path: "/profile/edit",
  },
  {
    component: NotFoundPage,
    path: "*",
  },
];

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Routes = () => {
  return (
    <Router>
      <Header />
      <Suspense
        fallback={
          <SpinnerContainer>
            <Spinner></Spinner>
          </SpinnerContainer>
        }
      >
        <Switch>
          {routes.map(({ component, path }) => (
            <Route
              key={`page_${path}`}
              component={component}
              exact
              path={path}
            ></Route>
          ))}
        </Switch>
      </Suspense>

      <Popup startDate={"2022-01-24"} endDate={"2222-02-02"}>
        Welcome to Mars Green. Mars Green is a digital art gallery for
        boundary-pushing artists.
        <br />
        <br />
        You need a{" "}
        <a href="https://metamask.io" target="_blank">
          MetaMask
        </a>{" "}
        wallet installed in the Chrome browser to purchase artworks from Mars
        Green.
        <br />
        <br />
        The new projects and artworks are not to be missed. We recommend that
        those interested sign up for our newsletter.
        <br />
        <br />
        For any inquires, please send an email to{" "}
        <a href="mailto:help@marsgreen.co" target="_blank">
          help@marsgreen.co
        </a>
      </Popup>
    </Router>
  );
};

export default Routes;
