import React from "react";
import { SyncLoader } from "react-spinners";

interface SpinnerProps {
  show?: boolean;
  color?: string;
}

const Spinner = (props: SpinnerProps) => {
  const { show = true, color = "#00FF00" } = props;
  return show ? (
    <SyncLoader color={color} speedMultiplier={0.65} size={20}></SyncLoader>
  ) : (
    <></>
  );
};

export default Spinner;
