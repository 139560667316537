import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useMediaQuery } from "react-responsive";
import { useScrollPosition } from "@src/hooks/";
import UserMenu from "./userMenu";
import NavMenu from "./navMenu";
import AnimatedLogo from "@src/components/Footer/atoms/AnimatedLogo";

const AnimatedLogoWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  filter: blur(6.5px);
  z-index: 9;
  transition: all 0.4s ease;

  @media (max-width: 1024px) {
    transform: translate(-50%, 25%) scale(0.5);
  }
`;

const Container = styled.div`
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  right: 0;
`;

const HeaderWrap = styled.div`
  max-width: 1600px;
  padding: 0 64px;
  min-height: 64px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding: 0 24px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0;
  transition: all 0.4s ease;
`;

const Navbar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const location = useLocation();
  const incheon = location.pathname?.includes("incheon-airport");
  const isHome = location.pathname === "/" && !isMobile;
  const [expand, setExpand] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [visible, setVisible] = useState(true);
  const [headerStyle, setHeaderStyle] = useState({
    boxShadow: "",
    transition: "all 0.4s ease-out",
    background: "rgba(255,255,255,0.92)",
    // minHeight: expand ? (isMobile ? 280 : 324) : 68,
    maxHeight: isHome ? 68 : "inherit",
  });

  useEffect(() => {
    setExpand(false);
  }, [location]);

  useScrollPosition(({ currPos, prevPos }) => {
    if (currPos.y < prevPos.y && currPos.y < -40) {
      setVisible(false);
    } else if (currPos.y > prevPos.y) setVisible(true);

    if (currPos.y < -10) setShadow(true);
    else setShadow(false);
  }, []);

  useEffect(() => {
    const shouldBeStyle = {
      boxShadow: isHome
        ? ""
        : expand || shadow
        ? "0 4px 45px rgb(0 0 0 / 5%)"
        : "",
      transition: "all 0.4s ease-out",
      background: isHome ? "transparent" : "rgba(255,255,255,0.92)",
      // minHeight: expand ? (isMobile ? 280 : isHome ? 324 : 280) : 68,
      maxHeight: isHome ? 68 : "inherit",
      transform: !visible && isMobile ? "translateY(-76px)" : "translateY(0px)",
    };
    if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

    setHeaderStyle(shouldBeStyle);
  }, [isHome, shadow, expand, visible, isMobile]);

  if (incheon)
    return (
      <AnimatedLogoWrapper>
        <AnimatedLogo></AnimatedLogo>
      </AnimatedLogoWrapper>
    );
  return (
    <>
      <header>
        <Backdrop
          onClick={() => setExpand(false)}
          style={{
            opacity: expand && isMobile ? 0.5 : 0,
            pointerEvents: expand && isMobile ? "auto" : "none",
          }}
        />
        <Container
          style={headerStyle}
          onMouseEnter={() => {
            if (!isMobile) setExpand(true);
          }}
          onMouseLeave={() => {
            if (!isMobile) setExpand(false);
          }}
          onClick={() => {
            if (expand) setExpand(false);
            else setExpand(true);
          }}
        >
          <HeaderWrap>
            <NavMenu
              expand={expand || isHome}
              isHome={isHome}
              isMobile={isMobile}
            />
            <UserMenu expand={expand} isHome={isHome} isMobile={isMobile} />
          </HeaderWrap>
        </Container>
      </header>

      <AnimatedLogoWrapper>
        <AnimatedLogo></AnimatedLogo>
      </AnimatedLogoWrapper>
    </>
  );
};

export default Navbar;
