import React from "react";
import Routes from "./routes/Routes";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import PersistedWeb3ReactProvider from "@src/contexts/PersistedWeb3ReactProvider";
import { SWRConfig } from "swr";

const getLibrary = (provider) => new ethers.providers.Web3Provider(provider);

function App() {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <PersistedWeb3ReactProvider>
          <SWRConfig
            value={{
              errorRetryInterval: 500,
            }}
          >
            <Routes />
          </SWRConfig>
        </PersistedWeb3ReactProvider>
      </Web3ReactProvider>
    </>
  );
}

export default App;
