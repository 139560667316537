import { User } from "@src/interfaces/user";

export enum UserActionType {
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
}

interface SignIn {
  type: UserActionType.SIGN_IN;
  payload: {
    user: User | null;
  };
}

interface SignOut {
  type: UserActionType.SIGN_OUT;
}

type UserAction = SignIn | SignOut;

interface UserState {
  user: User | null | undefined;
  signIn?: () => boolean;
  signUp?: (signUpForm: {
    username: string;
    email: string;
    walletAddress: string;
  }) => boolean;
  signOut?: () => void;
}

export const initialState: UserState = {
  user: null,
};

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.SIGN_IN:
      const user = action.payload.user;
      if (!user) return state;
      return {
        user: {
          ...user,
          socialMediaLinks: {
            ...user.socialMediaLinks,
          },
        },
      };
    case UserActionType.SIGN_OUT:
      return {
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
