import { User } from "@src/interfaces";
import { request } from "@src/utils";

const getUserById = async (id: string | number) => {
  return request.get<User>("/api/user", {
    params: {
      id,
    },
  });
};

export default getUserById;
