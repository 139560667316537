import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Container = styled.div`
  margin: 14px 0 14px;

  position: relative;
  z-index: 9;

  & ul {
    font-size: 13px;
    opacity: 0;
    margin-left: -2px;
    margin-top: -142%;
    transition: all 0.33s ease-in-out;
  }

  & ul.mobile {
    font-size: 15px;
    margin-left: -2px;
  }

  & ul.expand {
    margin-top: 0px;
    opacity: 1;
  }

  & ul.expand.mobile {
    margin-top: 40px;
  }

  & ul.expand.home {
    font-size: 15px;
  }

  & ul.expand.home.mobile {
  }
`;

const NavIcon = styled.div`
  position: absolute;
  z-index: 99;
  transition: opacity 0.4s ease;
  padding: 0;

  & span {
    display: block;
    width: 28px;
    height: 2px;
    margin: 7px 0;
    position: relative;
    background: #000;
    transition: all 0.4s ease;
  }

  & span:nth-of-type(1) {
    transform-origin: 0 0;
  }

  & span:nth-of-type(3) {
    transform-origin: 0 100%;
  }

  &.expand span:nth-of-type(1) {
    transform-origin: 0 0;
    transform: rotate(45deg) translateY(-1px);
  }

  &.expand span:nth-of-type(2) {
    opacity: 0;
    transform: scale(0);
  }

  &.expand span:nth-of-type(3) {
    transform: rotate(-45deg) translateY(1px);
  }
`;

const NavItem = styled.div`
  white-space: nowrap;
  position: relative;
  width: fit-content;
  font-size: 3em;
  line-height: 1.3;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0%;
    width: 0%;
    bottom: 2px;
    background: #00ff00;
    transition: all 0.33s ease;
  }

  &:hover {
    &:after {
      content: "";
      width: 100%;
      background-color: #00ff00;
    }
  }

  @media (max-width: 1024px) {
    font-size: 2.13em;
    line-height: 1.3;
  }
`;

const NavMenu = ({ expand = false, isHome = true, isMobile = false }) => {
  return (
    <Container>
      <NavIcon
        className={`${expand ? "expand" : ""} ${isMobile ? "mobile" : ""}`}
        style={{ opacity: expand && !isMobile ? 0 : 1 }}
      >
        <span></span>
        <span></span>
        <span></span>
      </NavIcon>
      <ul
        className={`${expand ? "expand" : ""} ${isMobile ? "mobile" : ""} ${
          isHome ? "home" : ""
        }`}
      >
        <NavItem>
          <Link to="/">Mars Green</Link>
        </NavItem>
        <NavItem>
          <Link to="/projects">Projects</Link>
        </NavItem>
        <NavItem>
          <Link to="/artists">Artists</Link>
        </NavItem>
        <NavItem>
          <Link to="/catalog">Catalog</Link>
        </NavItem>
        <NavItem>
          <Link to="/about">About</Link>
        </NavItem>
      </ul>
    </Container>
  );
};

export default NavMenu;
