import usePersistedReducer from "./usePersistedReducer";
import userReducer, { UserActionType } from "@src/contexts/userReducer";
import { User } from "@src/interfaces/user";

import { initialState } from "@src/contexts/userReducer";
import { isUser } from "@src/services/UserService";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const storageKey = "USER_STATE";

let loading = false;

const useUser = (): [User, (user: User) => void] => {
  const [state, dispatch] = usePersistedReducer(
    userReducer,
    initialState,
    storageKey
  );

  const location = useLocation();

  const setUser = (user) => {
    if (user)
      dispatch({
        type: UserActionType.SIGN_IN,
        payload: {
          user: user,
        },
      });
    else {
      dispatch({ type: UserActionType.SIGN_OUT });
    }
  };

  const init = async () => {
    try {
      if (loading) return;
      loading = true;
      const res = await isUser();
      setUser(res.data);
    } catch (e) {
      if (e?.response?.status == 403) {
        setUser(null);
      }
    } finally {
      loading = false;
    }
  };

  useEffect(() => {
    init();
  }, [location]);

  return [state.user, setUser];
};

export default useUser;
