import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { useUser } from "@src/hooks";
import { signout } from "@src/services/UserService";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 0;
  position: relative;
  text-align: right;
  align-items: flex-end;
  z-index: 9;

  & img {
    width: 32px;
    height: 32px;
    margin-left: 6px;
  }

  & ul {
    font-size: 16px;
    text-align: right;
    transition: all 0.4s ease-out;
    margin-top: -200px;
    transform: translateY(200px);
    height: 0;
  }

  & > a {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UserLink = styled.li`
  cursor: pointer;
  line-height: 1;
  position: relative;
  padding: 0 3px;
  overflow: hidden;
  transition: all 0.4s ease;

  &:nth-of-type(1) {
    margin-top: 16px;
  }

  &:nth-of-type(2) {
    margin-top: 6px;
  }

  & a {
    display: inline-block;
    position: relative;
    padding: 3px 0px;
  }
`;

const UserMenu = ({ expand = false, isHome = true, isMobile = false }) => {
  const [visible, setVisible] = useState(expand && !isHome);
  const [mouseOver, setMouseOver] = useState(expand && !isHome);
  const [user, setUser] = useUser();

  useEffect(() => {
    if (isHome && mouseOver) setVisible(true);
    else if (expand && !isHome) setVisible(true);
    else setVisible(false);
  }, [expand, isHome, mouseOver]);

  return (
    <Container
      onMouseEnter={() => {
        if (!isMobile) setMouseOver(true);
      }}
      onMouseLeave={() => {
        if (!isMobile) setMouseOver(false);
      }}
    >
      <Link
        to={user ? `#` : "/signin"}
        style={{ fontSize: 18 }}
        className="hover-green-light"
      >
        <span>{user ? user.username : "Sign In"}</span>
      </Link>
      <ul style={{ opacity: visible ? 1 : 0 }}>
        {user ? (
          <>
            <UserLink>
              <Link to={`/user/${user.id}`} className="hover-green-light">
                Profile
              </Link>
            </UserLink>
            <UserLink>
              <a
                onClick={async () => {
                  try {
                    await signout();
                  } catch (e) {
                    console.error(e);
                  }
                  setUser(null);
                  location.reload();
                }}
                className="hover-green-light"
              >
                Sign Out
              </a>
            </UserLink>
          </>
        ) : null}
      </ul>
    </Container>
  );
};

export default UserMenu;
